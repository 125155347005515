import React, {useEffect, useRef} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import b_col1 from "../../static/img/blog/b_col1.jpg"
import b_col2 from "../../static/img/blog/b_col2.jpg"
import b_col3 from "../../static/img/blog/b_col3.jpg"
import b_col4 from "../../static/img/blog/b_col4.jpg"
import b_col5 from "../../static/img/blog/b_col5.jpg"
import b_col6 from "../../static/img/blog/b_col6.jpg"
import b_col7 from "../../static/img/blog/b_col7.jpg"
import b_col8 from "../../static/img/blog/b_col8.jpg"

const Blog = () => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout>
            <SEO title="Home"/>
            <Banner/>
            <section className="main_blog_area section_gap">
                <div className="container">
                    <div className="row main_blog_inner blog_col">
                        <div className="col-md-6">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={b_col1}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            25 Dec. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  By : <a href="#">Admin</a>
                </span>
                                            <span>
                  <a href="#">business,</a>
                  <a href="#">marketing</a>
                </span>
                                            <span>
                  Comments: <a href="#">5</a>
                </span>
                                        </div>
                                    </div>
                                    <a href="#">
                                        <h2>Professionally cultivate one to one customer</h2>
                                    </a>
                                    <p>
                                        Objectively innovate empowered manufactured products whereas
                                        parallel platforms. Holisticly predominate extensible testing
                                        procedures for reliable supply chains. Dramatically engage
                                        top-line web services vis-a-vis cutting-edge deliverables.
                                        Proactively envisioned ...
                                    </p>
                                    <a className="b_readmore btn_hover" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={b_col2}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            25 Dec. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  By : <a href="#">Admin</a>
                </span>
                                            <span>
                  <a href="#">business,</a>
                  <a href="#">marketing</a>
                </span>
                                            <span>
                  Comments: <a href="#">5</a>
                </span>
                                        </div>
                                    </div>
                                    <a href="#">
                                        <h2>Leverage agile frameworks to provide</h2>
                                    </a>
                                    <p>
                                        Objectively innovate empowered manufactured products whereas
                                        parallel platforms. Holisticly predominate extensible testing
                                        procedures for reliable supply chains. Dramatically engage
                                        top-line web services vis-a-vis cutting-edge deliverables.
                                        Proactively envisioned ...
                                    </p>
                                    <a className="b_readmore btn_hover" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={b_col3}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            25 Dec. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  By : <a href="#">Admin</a>
                </span>
                                            <span>
                  <a href="#">business,</a>
                  <a href="#">marketing</a>
                </span>
                                            <span>
                  Comments: <a href="#">5</a>
                </span>
                                        </div>
                                    </div>
                                    <a href="#">
                                        <h2>Professionally cultivate one to one customer</h2>
                                    </a>
                                    <p>
                                        Objectively innovate empowered manufactured products whereas
                                        parallel platforms. Holisticly predominate extensible testing
                                        procedures for reliable supply chains. Dramatically engage
                                        top-line web services vis-a-vis cutting-edge deliverables.
                                        Proactively envisioned ...
                                    </p>
                                    <a className="b_readmore btn_hover" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={b_col4}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            25 Dec. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  By : <a href="#">Admin</a>
                </span>
                                            <span>
                  <a href="#">business,</a>
                  <a href="#">marketing</a>
                </span>
                                            <span>
                  Comments: <a href="#">5</a>
                </span>
                                        </div>
                                    </div>
                                    <a href="#">
                                        <h2>Leverage agile frameworks to provide</h2>
                                    </a>
                                    <p>
                                        Objectively innovate empowered manufactured products whereas
                                        parallel platforms. Holisticly predominate extensible testing
                                        procedures for reliable supply chains. Dramatically engage
                                        top-line web services vis-a-vis cutting-edge deliverables.
                                        Proactively envisioned ...
                                    </p>
                                    <a className="b_readmore btn_hover" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={b_col5}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            25 Dec. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  By : <a href="#">Admin</a>
                </span>
                                            <span>
                  <a href="#">business,</a>
                  <a href="#">marketing</a>
                </span>
                                            <span>
                  Comments: <a href="#">5</a>
                </span>
                                        </div>
                                    </div>
                                    <a href="#">
                                        <h2>Professionally cultivate one to one customer</h2>
                                    </a>
                                    <p>
                                        Objectively innovate empowered manufactured products whereas
                                        parallel platforms. Holisticly predominate extensible testing
                                        procedures for reliable supply chains. Dramatically engage
                                        top-line web services vis-a-vis cutting-edge deliverables.
                                        Proactively envisioned ...
                                    </p>
                                    <a className="b_readmore btn_hover" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={b_col6}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            25 Dec. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  By : <a href="#">Admin</a>
                </span>
                                            <span>
                  <a href="#">business,</a>
                  <a href="#">marketing</a>
                </span>
                                            <span>
                  Comments: <a href="#">5</a>
                </span>
                                        </div>
                                    </div>
                                    <a href="#">
                                        <h2>Leverage agile frameworks to provide</h2>
                                    </a>
                                    <p>
                                        Objectively innovate empowered manufactured products whereas
                                        parallel platforms. Holisticly predominate extensible testing
                                        procedures for reliable supply chains. Dramatically engage
                                        top-line web services vis-a-vis cutting-edge deliverables.
                                        Proactively envisioned ...
                                    </p>
                                    <a className="b_readmore btn_hover" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={b_col7}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            25 Dec. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  By : <a href="#">Admin</a>
                </span>
                                            <span>
                  <a href="#">business,</a>
                  <a href="#">marketing</a>
                </span>
                                            <span>
                  Comments: <a href="#">5</a>
                </span>
                                        </div>
                                    </div>
                                    <a href="#">
                                        <h2>Professionally cultivate one to one customer</h2>
                                    </a>
                                    <p>
                                        Objectively innovate empowered manufactured products whereas
                                        parallel platforms. Holisticly predominate extensible testing
                                        procedures for reliable supply chains. Dramatically engage
                                        top-line web services vis-a-vis cutting-edge deliverables.
                                        Proactively envisioned ...
                                    </p>
                                    <a className="b_readmore btn_hover" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={b_col8}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            25 Dec. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  By : <a href="#">Admin</a>
                </span>
                                            <span>
                  <a href="#">business,</a>
                  <a href="#">marketing</a>
                </span>
                                            <span>
                  Comments: <a href="#">5</a>
                </span>
                                        </div>
                                    </div>
                                    <a href="#">
                                        <h2>Leverage agile frameworks to provide</h2>
                                    </a>
                                    <p>
                                        Objectively innovate empowered manufactured products whereas
                                        parallel platforms. Holisticly predominate extensible testing
                                        procedures for reliable supply chains. Dramatically engage
                                        top-line web services vis-a-vis cutting-edge deliverables.
                                        Proactively envisioned ...
                                    </p>
                                    <a className="b_readmore btn_hover" href="#">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Blog
